import React from "react"
// import { graphql } from "gatsby"
// import ReactMarkdown from "react-markdown"
import Layout from "../components/_App/layout"
import SEO from "../components/_App/seo"
// import Navbar from "../components/_App/Navbar"
// import Footer from "../components/_App/Footer"
// import PageBanner from '../components/Common/PageBanner'
// import BlogSidebar from '../components/Blog/BlogSidebar';
// import * as Icon from 'react-feather';
// import { Link } from "gatsby"; 

const BlogDetails = ({ data }) => {
    // console.log(data)
    // const { 
    //     title, 
    //     longDescription, 
    //     postDate, 
    //     author, 
    //     authorLink,
    //     image: {
    //         childImageSharp: {
    //             fluid: { src }
    //         }
    //     }
    // } = data.blog
    return(
        <Layout>
            

           

           

            <div className="blog-details-area ptb-80">
                <div className="container">
                    
                </div>
            </div>
 
           
        </Layout>
    )
}

export default BlogDetails